import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import ReactHtmlParser from 'react-html-parser'
import { HelmetDatoCms } from 'gatsby-source-datocms'

import Layout from '../components/layout'
import ContactForm from '../components/contactForm'

const ContactPage = ({ data: { datoCmsContactPage: contact } }) => (
  <Layout>
    <HelmetDatoCms seo={contact.seoMetaTags} />
    <section
      className="hero is-large is-page-hero"
      style={{
        backgroundImage: `url(${contact.heroBackgroundImage.fixed.src})`
      }}
    >
      <div className="hero-body">
        <div className="container">
          <h1 className="title has-text-white has-text-weight-normal">
            {contact.heroTitle}
          </h1>
        </div>
      </div>
    </section>
    <section className="section" style={{ marginBottom: '6rem' }}>
      <div className="columns is-centered">
        <div className="column is-half">
          <div className="content">
            {ReactHtmlParser(contact.contactTextNode.childMarkdownRemark.html)}
          </div>
          <br />
          <hr />
          <br />
          <ContactForm />
        </div>
      </div>
    </section>
  </Layout>
)

ContactPage.propTypes = {
  data: PropTypes.object.isRequired
}

export default ContactPage

export const query = graphql`
  query ContactQuery {
    datoCmsContactPage {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      heroTitle
      heroBackgroundImage {
        fixed(
          width: 1920
          height: 600
          imgixParams: {
            h: "800"
            auto: "compress"
            fm: "pjpg"
            fit: "crop"
            crop: "focalpoint"
            # mono: "504F4F"
            bri: -10
          }
        ) {
          src
        }
      }
      contactTextNode {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
